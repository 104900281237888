import Vue from 'vue';

const state = {
  metadata: {
    buildings: {},
    totalCount: 0,
    loadedCount: 0
  },
  meteringData: {},
  calculatedKPIs: {},
  loadingStates: {
    buildings: new Set(),
    metadata: false,
    calculation: false
  },
  filters: {
    search: '',
    sortBy: null,
    sortDesc: false,
    dateRange: {
      currentPeriod: {
        from: null,
        to: null
      },
      previousPeriod: {
        from: null,
        to: null
      }
    }
  },
  pagination: {
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0
  }
};

const mutations = {
  SET_BUILDING_METADATA(state, { buildingId, metadata }) {
    Vue.set(state.metadata.buildings, buildingId, metadata);
  },

  SET_BUILDINGS_METADATA(state, buildings) {
    state.metadata.buildings = buildings;
    state.metadata.totalCount = Object.keys(buildings).length;
  },

  SET_METERING_DATA(state, { buildingId, period, type, data }) {
    if (!state.meteringData[buildingId]) {
      Vue.set(state.meteringData, buildingId, {
        current: {},
        previous: {}
      });
    }
    Vue.set(state.meteringData[buildingId][period], type, data);
  },

  SET_CALCULATED_KPIS(state, { buildingId, kpis }) {
    Vue.set(state.calculatedKPIs, buildingId, kpis);
  },

  SET_LOADING_STATE(state, { buildingId, loading }) {
    if (loading) {
      state.loadingStates.buildings.add(buildingId);
    } else {
      state.loadingStates.buildings.delete(buildingId);
    }
  },

  SET_METADATA_LOADING(state, loading) {
    state.loadingStates.metadata = loading;
  },

  SET_FILTERS(state, filters) {
    state.filters = { ...state.filters, ...filters };
  },

  SET_PAGINATION(state, pagination) {
    state.pagination = { ...state.pagination, ...pagination };
  }
};

const actions = {
  setFilters({ commit, dispatch }, filters) {
    commit('SET_FILTERS', filters);
    
    if (filters.dateRange || filters.search) {
      dispatch('loadVisibleBuildingsData');
    }
  },

  setPagination({ commit, dispatch }, pagination) {
    commit('SET_PAGINATION', pagination);
    dispatch('loadVisibleBuildingsData');
  },

  async initializeData({ dispatch, rootState }) {
    await dispatch('buildings/fetchOrganisationBuildingsWithParametersAndKpis', rootState.contextOrg.id, { root: true });
    await dispatch('fetchBuildingsList');
  },

  async fetchBuildingsList({ commit, dispatch, rootState }) {
    commit('SET_METADATA_LOADING', true);
    try {
      // Use the buildings from the buildings store
      const buildings = rootState.buildings.buildings;

      const buildingsMetadata = buildings.reduce((acc, building) => {
        acc[building.id] = {
          ...building,
          parameters: building.parameters || {
            area: building.parameters?.grossFloorArea || 0,
          }
        };
        return acc;
      }, {});

      commit('SET_BUILDINGS_METADATA', buildingsMetadata);
    } catch (error) {
      console.error('Error processing buildings list:', error);
    }
  },

  async loadBuildingData({ dispatch, commit, state }, buildingId) {
    if (state.loadingStates.buildings.has(buildingId)) return;
    
    commit('SET_LOADING_STATE', { buildingId, loading: true });
    
    try {
      const { currentPeriod, previousPeriod } = state.filters.dateRange;
      const types = ['ELECTRICITY', 'WATER', 'DISTRICT_HEATING'];
      
      if (!currentPeriod?.from || !currentPeriod?.to || !previousPeriod?.from || !previousPeriod?.to) {
        console.warn('Date range not properly set');
        return;
      }

      // Load data for each type and period
      await Promise.all(types.flatMap(type => [
        dispatch('fetchBuildingMeteringData', {
          buildingId,
          meteringPointSubtype: type,
          dateTimeFrom: currentPeriod.from,
          dateTimeTo: currentPeriod.to,
          period: 'current'
        }),
        dispatch('fetchBuildingMeteringData', {
          buildingId,
          meteringPointSubtype: type,
          dateTimeFrom: previousPeriod.from,
          dateTimeTo: previousPeriod.to,
          period: 'previous'
        })
      ]));

      // Calculate KPIs once we have the data
      dispatch('calculateBuildingKPIs', buildingId);
    } catch (error) {
      console.error(`Error loading data for building ${buildingId}:`, error);
    } finally {
      commit('SET_LOADING_STATE', { buildingId, loading: false });
    }
  },

  async loadVisibleBuildingsData({ state, dispatch }) {
    const { currentPage, itemsPerPage } = state.pagination;
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    
    const visibleBuildingIds = Object.keys(state.metadata.buildings)
      .slice(start, end);
    
    await Promise.all(
      visibleBuildingIds.map(buildingId => 
        dispatch('loadBuildingData', buildingId)
      )
    );
  },

  calculateBuildingKPIs({ commit, state }, buildingId) {
    const building = state.metadata.buildings[buildingId];
    const meteringData = state.meteringData[buildingId];
    
    if (!building || !meteringData) return;

    // Calculate KPIs
    const kpis = {
      electricityTotal: calculateTotalUsage(meteringData.current.ELECTRICITY?.meter_energy_consumption),
      waterTotal: calculateTotalUsage(meteringData.current.WATER?.meter_volume),
      heatingTotal: calculateTotalUsage(meteringData.current.DISTRICT_HEATING?.meter_energy_consumption_total),

      electricityPerM2: calculateUsagePerM2(meteringData.current.ELECTRICITY.meter_energy_consumption, building.parameters.grossFloorArea),
      waterPerM2: calculateUsagePerM2(meteringData.current.WATER.meter_volume, building.parameters.grossFloorArea),
      heatingPerM2: calculateUsagePerM2(meteringData.current.DISTRICT_HEATING.meter_energy_consumption_total, building.parameters.grossFloorArea),
      
      electricityYoY: calculateYearOverYearChange(
        meteringData.current.ELECTRICITY.meter_energy_consumption,
        meteringData.previous.ELECTRICITY.meter_energy_consumption
      ),
      waterYoY: calculateYearOverYearChange(
        meteringData.current.WATER.meter_volume,
        meteringData.previous.WATER.meter_volume
      ),
      heatingYoY: calculateYearOverYearChange(
        meteringData.current.DISTRICT_HEATING.meter_energy_consumption_total,
        meteringData.previous.DISTRICT_HEATING.meter_energy_consumption_total
      )
    };

    commit('SET_CALCULATED_KPIS', { buildingId, kpis });
  },

  async fetchBuildingMeteringData({ commit }, { period, ...requestParams }) {
    const {
      buildingId,
      meteringPointSubtype,
      dateTimeFrom,
      dateTimeTo
    } = requestParams;

    // Default values for aggregation
    const aggregateWindowDuration = 1;
    const aggregateWindowDurationUnit = 'YEARS';

    try {
      const params = new URLSearchParams({
        meteringPointSubtype,
        dateTimeFrom: dateTimeFrom.toISOString(),
        dateTimeTo: dateTimeTo.toISOString(),
        aggregateWindowDuration,
        aggregateWindowDurationUnit
      });

      const response = await Vue.axios.get(
        `/dashboard/buildings/${buildingId}/metering-points-data?${params.toString()}`
      );

      let processedData = {};

      if (response.data && Array.isArray(response.data) && response.data.length > 0) {
        processedData = response.data.reduce((acc, item) => {
          const { field, time, value } = item;
          if (!field || !time || !value) return acc;
          
          if (!acc[field]) acc[field] = [];
          acc[field].push({
            time,
            value: parseFloat(value) || 0
          });
          
          return acc;
        }, {});
      }

      commit('SET_METERING_DATA', { 
        buildingId, 
        period, 
        type: meteringPointSubtype, 
        data: processedData 
      });
      
      return processedData;
    } catch (error) {
      console.error('Error fetching building metering data:', error);
      commit('SET_METERING_DATA', { 
        buildingId, 
        period, 
        type: meteringPointSubtype, 
        data: {} 
      });
      throw error;
    }
  },
};

const getters = {
  visibleBuildings: (state, getters, rootState) => {
    const buildings = rootState.buildings.buildings;
    const { currentPage, itemsPerPage } = state.pagination;
    const start = (currentPage - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    return Object.values(state.metadata.buildings)
      .slice(start, end)
      .map(building => {
        const originalBuilding = buildings.find(b => b.id === building.id);
        return {
          ...building,
          parameters: originalBuilding?.parameters || {},
          kpis: state.calculatedKPIs[building.id] || {},
          loading: state.loadingStates.buildings.has(building.id)
        };
      });
  },

  isBuildingLoading: state => buildingId => 
    state.loadingStates.buildings.has(buildingId),

  getBuildingKPIs: state => buildingId =>
    state.calculatedKPIs[buildingId] || null,

  filteredBuildingCount: state =>
    Object.keys(state.metadata.buildings).length
};

// Helper functions for KPI calculations

function calculateTotalUsage(data) {
  if (!data) return null;
  return data.reduce((sum, item) => sum + item.value, 0);
}

function calculateUsagePerM2(data, area) {
  if (!data || !area) return null;
  const totalUsage = data.reduce((sum, item) => sum + item.value, 0);
  return totalUsage / area;
}

function calculateYearOverYearChange(currentData, previousData) {
  if (!currentData || !previousData) return null;
  
  const currentTotal = currentData.reduce((sum, item) => sum + item.value, 0);
  const previousTotal = previousData.reduce((sum, item) => sum + item.value, 0);
  
  if (previousTotal === 0) return null;
  
  return ((currentTotal - previousTotal) / previousTotal) * 100;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};