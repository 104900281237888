import Vue from "vue"
import * as commons from '@/commons'
import router from '@/router'
import moment from 'moment-timezone'
import i18n from "@/i18n"

// default state
const getDefaultState = () => {

  return {
    selectedDevice: null,
    availableSensorAttributes: [],
    graphConfig: {
      dateRange: {
        startDate: null,
        endDate: null
      },
      selectedMeanDuration: "P1D",
      selectedSensorAttributes: [],
      selectedGraphType: 'DAYS',
      enableWeekends: true
    }
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  graphConfigDateTimeRangeFormatted: state => {
    if(state.graphConfig.dateRange.startDate && state.graphConfig.dateRange.endDate && state.selectedDevice && state.selectedDevice.building) {
      return moment(state.graphConfig.dateRange.startDate).format(i18n.t('dateTimeShortFormatMoment'))
          + ' - '
          + moment(state.graphConfig.dateRange.endDate).format(i18n.t('dateTimeShortFormatMoment'))
    } else {
      return ''
    }
  }
}

// action
const actions = {

  appInit({ commit, state, dispatch, rootState }) {
    console.log('graphMeteringPoints/appInit')
    dispatch('resetGraphConfig')
  },

  resetGraphConfig({ commit, state }, data) {
    console.log('graphMeteringPoints/resetGraphConfig')

    return new Promise((resolve) => {
      let startDate = new Date()
      startDate.setDate(startDate.getDate() - 6)
      startDate.setHours(0, 0, 0, 0)
      let endDate = new Date()
      //endDate.setHours(23, 59, 59, 999)
      commit('UPDATE_GRAPH_CONFIG', {
        dateRange: {startDate: startDate, endDate: endDate},
        selectedSensorAttributes: []
      })
      resolve()
    })
  },

  loadDeviceFromRouteQueryParam({ commit, state, dispatch }){
    if (router.currentRoute.query.deviceId) {
      if (state.selectedDevice && state.selectedDevice.id==router.currentRoute.query.deviceId) {
        dispatch('loadSelectedDeviceSensorValues')
      } else {
        //load device from server
        Vue.axios.get('/devices/' + router.currentRoute.query.deviceId).then(response => {
          commit('UPDATE_SELECTED_DEVICE', response.data)
          dispatch('resetGraphConfig')
          dispatch('loadSelectedDeviceSensorValues')
        }).catch(error => {
          commons.processRestError(error)
        })
      }
    }else{
      commit('UPDATE_SELECTED_DEVICE', null)
      dispatch('resetGraphConfig')
    }
  },

  loadSelectedDeviceSensorValues({ commit, state, dispatch, rootState }){

    console.log('loadSelectedDeviceSensorValues dateRange ' + JSON.stringify(state.graphConfig.dateRange))

    let params = {}

    params.dateTimeFrom = state.graphConfig.dateRange.startDate
    params.dateTimeFrom.setHours(0, 0, 0, 0)
    params.dateTimeFrom = moment(state.graphConfig.dateRange.startDate).format('YYYY-MM-DDTHH:mm:ss')
    let nowInBuildingTimezone = moment.tz(moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), state.selectedDevice.building.timeZone)
    let endDateInBuildingTimezone = moment.tz(moment(state.graphConfig.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss'), state.selectedDevice.building.timeZone)
    if(!endDateInBuildingTimezone.isSame(nowInBuildingTimezone,'day')) {
      params.dateTimeTo = state.graphConfig.dateRange.endDate
      params.dateTimeTo.setHours(23, 59, 59, 999)
      params.dateTimeTo = moment(state.graphConfig.dateRange.endDate).format('YYYY-MM-DDTHH:mm:ss')
    }

    const diffMillis = Math.abs(state.graphConfig.dateRange.endDate - state.graphConfig.dateRange.startDate)
    if(diffMillis <= (1000 * 60 * 60 * 24 * 1)){
      // no aggregation
      commit('UPDATE_GRAPH_CONFIG',  { selectedGraphType: 'HOURS' })
    } else if(diffMillis <= (1000 * 60 * 60 * 24 * 60)){
      params.aggregateWindowDuration = "1"
      params.aggregateWindowDurationUnit = "HOURS"
      params.aggregationType = "SUM"
      commit('UPDATE_GRAPH_CONFIG',  { selectedGraphType: 'DAYS' })
    } else {
      params.aggregateWindowDuration = "1"
      params.aggregateWindowDurationUnit = "DAYS"
      params.aggregationType = "SUM"
      params.dateTimeTo = moment(new Date(state.graphConfig.dateRange.endDate.getFullYear(), state.graphConfig.dateRange.endDate.getMonth() + 1, 0, 23, 59, 59)).format('YYYY-MM-DDTHH:mm:ss')
      commit('UPDATE_GRAPH_CONFIG',  { selectedGraphType: 'MONTHS' })
    }

    let promiseSensorValues = new Promise((resolve, reject) => {

      Vue.axios.get('/devices/' + state.selectedDevice.id + '/metering-point-values', { params: params }).then(response => {
        resolve(response.data)
      }).catch(error => {
        commons.processRestError(error)
        reject(error)
      })
    })

    let promiseSensorValuesAverage = new Promise((resolve, reject) => {

      if (diffMillis <= (1000 * 60 * 60 * 24 * 1)) {
        //no aggregation
      } else if (diffMillis <= (1000 * 60 * 60 * 24 * 60)) {
        params.aggregateWindowDuration = "1"
        params.aggregateWindowDurationUnit = "HOURS"
        params.aggregationType = "AVERAGE"
      } else {
        params.aggregateWindowDuration = "1"
        params.aggregateWindowDurationUnit = "DAYS"
        params.aggregationType = "AVERAGE"
      }

      params.aggregateFromStart = true;

      Vue.axios.get('/devices/' + state.selectedDevice.id + '/metering-point-values', { params: params }).then(response => {
        resolve(response.data)
      }).catch(error => {
        commons.processRestError(error)
        reject(error)
      })
    })

    Promise.all([promiseSensorValues, promiseSensorValuesAverage]).then(function(responsesData) {
      commit('UPDATE_SENSOR_VALUES', responsesData)
    }).catch((error) => {
      commons.processRestError(error)
    })
  },

  graphConfigDateRangeSelected({ commit, state, dispatch }, data){
    commit('UPDATE_GRAPH_CONFIG',  { dateRange: data.dateRange })
    dispatch('loadSelectedDeviceSensorValues')
  },

  graphConfigSensorAttributesChanged({ commit, state, dispatch }, data){
    commit('UPDATE_GRAPH_CONFIG',  { selectedSensorAttributes:  data })
  },

  selectedMeanDurationSelected({ commit, state, dispatch }, data){
    commit('UPDATE_GRAPH_CONFIG',  { selectedMeanDuration: data })
    dispatch('loadSelectedDeviceSensorValues')
  },

  graphConfigOptionsChanged({ commit, state, dispatch }, data){
    commit('UPDATE_GRAPH_CONFIG',  { graphOptions: data })
  }

}

// mutations
const mutations = {

  ['GLOBAL_RESET']: (state, data) => {
    Object.assign(state, getDefaultState())
  },

  ['UPDATE_SELECTED_DEVICE']: (state, data) => {
    state.selectedDevice = data
  },

  ['UPDATE_GRAPH_CONFIG']: (state, data) => {
    if(data.dateRange){
      state.graphConfig.dateRange = data.dateRange
    }
    if(data.selectedSensorAttributes){
      state.graphConfig.selectedSensorAttributes = data.selectedSensorAttributes
    }
    if(data.selectedMeanDuration){
      state.graphConfig.selectedMeanDuration = data.selectedMeanDuration
    }
    if(data.graphOptions){
      // state.graphConfig.graphOptions = data.graphOptions
      state.graphConfig = Object.assign({}, state.graphConfig, data.graphOptions)
    }
    if(data.selectedGraphType){
      state.graphConfig.selectedGraphType = data.selectedGraphType
    }
  },

  ['UPDATE_SENSOR_VALUES']: (state, data) => {
    if (!data || data.every(item => item === '')) {
      Vue.set(state.selectedDevice, 'sensorValues', []);
      Vue.set(state.selectedDevice, 'sensorValuesMap', new Map());
      Vue.set(state.selectedDevice, 'sensorAverageValues', []);
      Vue.set(state.selectedDevice, 'sensorAverageValuesMap', new Map());
      Vue.set(state.graphConfig, 'selectedSensorAttributes', []);
      state.availableSensorAttributes = [];
      return;
    }

    state.availableSensorAttributes = commons.getAvailableSensorAttributesFromSensorData(data[0])
    if (state.availableSensorAttributes.length > 0 && state.graphConfig.selectedSensorAttributes.length == 0) {
      // select first attribute
      console.log('setting state.graphConfig.selectedSensorAttributes ' + JSON.stringify([state.availableSensorAttributes[0]]))
      Vue.set(state.graphConfig, 'selectedSensorAttributes', [state.availableSensorAttributes[0]])
    }
    Vue.set(state.selectedDevice, 'sensorValues', data[0])
    Vue.set(state.selectedDevice, 'sensorValuesMap', commons.processSensorData(data[0]))
    Vue.set(state.selectedDevice, 'sensorAverageValues', data[1])
    Vue.set(state.selectedDevice, 'sensorAverageValuesMap', commons.processSensorData(data[1]))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}