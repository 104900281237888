<template>
  <div class="animated fadeInRight">

    <div class="row">

      <div class="col-xxxl-8">

        <div class="ibox">
          <div class="ibox-content p-5">

            <div class="d-flex justify-content-between align-items-center mb-4">
              <h2 class="mb-0">{{ $t('orgAdmin.form.details.title') }}</h2>
              <div class="row">
                <div class="col-12">
                  <button  type="button" class="btn btn-sm btn-primary" v-if="type === 'view'" @click.prevent="editForm">
                    <i class="fa fa-edit"></i>&nbsp;{{ $t('orgAdmin.form.actions.edit') }}
                  </button>
                  <button type="submit" v-if="type === 'create'" class="btn btn-sm btn-primary" @click="submit">{{ $t('common.actions.create') }}</button>
                  <button type="submit" v-if="type === 'edit'" class="btn btn-sm btn-primary" @click="submit">{{ $t('common.actions.save') }}</button>
                  <button type="button" v-if="type === 'create' || type === 'edit'" class="btn btn-sm btn-secondary m-l-sm" @click="reset">{{ $t('common.actions.reset') }}</button>
                  <button type="button" class="btn btn-sm btn-white m-l-sm" @click="cancel" v-if="type === 'edit'">{{ $t('common.actions.cancel') }}</button>
                  <button type="button" class="btn btn-sm btn-white m-l-sm" @click="back">{{ $t('common.actions.back') }}</button>
                </div>
              </div>
            </div>

            <div class="alert alert-danger alert-dismissible" v-show="error">{{ error }}
              <button class="close" type="button" @click="error = ''">×</button>
            </div>

            <validation-observer ref="form" v-slot="{ handleSubmit }">

              <b-form class="m-t" role="form" @submit.stop.prevent="handleSubmit(submit)">

                <div class="row">

                  <div class="col-12">

                    <validation-provider name="orgAdmin.form.attributes.name" :rules="{ required: true, min: 3, max: 100 }" v-slot="validationContext">
                      <b-form-group id="input-group-form-name" :label="$t('orgAdmin.form.attributes.name')" label-for="input-form-name" label-cols-sm="2">
                        <b-form-input
                            :plaintext="type === 'view'"
                            id="input-form-name"
                            alias="input-form-name"
                            v-model="form.name"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-feedback-form-name"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-feedback-form-name">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <validation-provider name="orgAdmin.form.attributes.status" :rules="{ required: true }" v-slot="validationContext">
                      <b-form-group id="input-group-form-status" :label="$t('orgAdmin.form.attributes.status')" label-for="input-form-status" label-cols-sm="2">
                        <v-select
                            :disabled="type === 'view'"
                            id="input-form-status"
                            name="input-form-status"
                            v-model="form.status"
                            :options="formStatus"
                            :reduce="type => type.id"
                            label="text"
                            :clearable="false"
                            :class="{'is-invalid': validationContext.errors.length > 0}">
                        </v-select>
                        <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <!-- add settings -->

                    <div class="row">
                      <div class="col-md-7">
                        <b-card content-class="px-2 py-2 white-bg" :title="$t('orgAdmin.form.details.title')">
                            <div class="row">
                              <div class="col-12">
                                <vuedraggable 
                                  v-if="type === 'edit'"
                                  v-model="formFieldsSorted"
                                  v-bind="dragOptions"
                                  class="field-list"
                                  @start="drag=true"
                                  @end="onDragEnd"
                                >
                                  <div v-for="(field, index) in formFieldsSorted" :key="index" class="field-wrapper" 
                                    :class="[
                                      selectedFieldIndex === index ? 'selected' : '',
                                      highlightedFields.includes(index) ? 'highlight-invalid' : ''
                                    ]"
                                  >
                                    <div class="field-header">
                                      <i class="fa-solid fa-grip handle mr-2"></i>
                                      <span class="field-title">
                                        {{ field.title }}
                                        <span v-if="field.required" class="text-danger ms-1">*</span>
                                      </span>
                                      <div class="field-actions">
                                        <i class="fa-solid fa-pen-to-square" @click="editField(index)" title="Edit field"></i>
                                        <i class="fa-solid fa-trash text-danger" @click="removeField(index)" title="Remove field"></i>
                                      </div>
                                    </div>
                                    <div class="field-editor">
                                      <div v-if="field.type === 'text'" class="field">
                                        <input type="text" class="form-control" :placeholder="field.title">
                                      </div>
                                      <div v-else-if="field.type === 'textarea'" class="field">
                                        <textarea class="form-control" :placeholder="field.title"></textarea>
                                      </div>
                                      <div v-else-if="field.type === 'radio'" class="field">
                                        <div v-for="(option, option_index) in field.options" :key="option_index" class="form-check">
                                          <input type="radio" 
                                                 class="form-check-input" 
                                                 :name="field.name" 
                                                 :id="field.name + '_option_' + option_index">
                                          <label class="form-check-label" :for="field.name + '_option_' + option_index">
                                            {{option.label || 'Option ' + (option_index + 1)}}
                                          </label>
                                        </div>
                                      </div>
                                      <div v-else-if="field.type === 'checkbox'" class="field">
                                        <div v-for="(option, option_index) in field.options" :key="option_index" class="form-check">
                                          <input type="checkbox" 
                                                 class="form-check-input" 
                                                 :name="field.name" 
                                                 :id="field.name + '_option_' + option_index">
                                          <label class="form-check-label" :for="field.name + '_option_' + option_index">
                                            {{option.label || 'Option ' + (option_index + 1)}}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </vuedraggable>
                      
                                <div v-else class="fields-preview">
                                  <div v-for="(field, index) in formFieldsSorted" 
                                       :key="index" 
                                       class="field-wrapper"
                                  >
                                    <div class="field-header">
                                      <span class="field-title">
                                        {{ field.title }}
                                        <span v-if="field.required" class="text-danger ms-1">*</span>
                                      </span>
                                    </div>
                                    <div class="field-editor">
                                      <div v-if="field.type === 'text'" class="field">
                                        <input type="text" class="form-control" :placeholder="field.title" disabled>
                                      </div>
                                      <div v-else-if="field.type === 'textarea'" class="field">
                                        <textarea class="form-control" :placeholder="field.title" disabled></textarea>
                                      </div>
                                      <div v-else-if="field.type === 'radio'" class="field">
                                        <div v-for="(option, option_index) in field.options" :key="option_index" class="form-check">
                                          <input type="radio" 
                                                 class="form-check-input" 
                                                 :name="field.name" 
                                                 :id="'view_' + field.name + '_option_' + option_index" 
                                                 disabled>
                                          <label class="form-check-label" :for="'view_' + field.name + '_option_' + option_index">
                                            {{option.label || 'Option ' + (option_index + 1)}}
                                          </label>
                                        </div>
                                      </div>
                                      <div v-else-if="field.type === 'checkbox'" class="field">
                                        <div v-for="(option, option_index) in field.options" :key="option_index" class="form-check">
                                          <input type="checkbox" 
                                                 class="form-check-input" 
                                                 :name="field.name" 
                                                 :id="'view_' + field.name + '_option_' + option_index" 
                                                 disabled>
                                          <label class="form-check-label" :for="'view_' + field.name + '_option_' + option_index">
                                            {{option.label || 'Option ' + (option_index + 1)}}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </b-card>
                      </div>
                      <div class="col-md-5">
                        <div content-class="px-3 py-3 white-bg" v-if="type === 'edit'">
                          <b-card :title="$t('orgAdmin.form.details.addField')">
                            <div class="field-types-container">
                              <div v-for="fieldType in fieldTypes" 
                                   :key="fieldType.type" 
                                   class="field-type-button"
                                   @click="addField(fieldType.type)"
                              >
                                <i :class="fieldType.icon"></i>
                                <span>{{ fieldType.label }}</span>
                              </div>
                            </div>
                          </b-card>
                      
                          <b-card class="mt-2" :title="$t('orgAdmin.form.details.editField')">
                            <div class="field-editor-panel" v-if="selectedField">
                              <div class="form-group">
                                <label class="control-label">{{ $t('orgAdmin.form.details.fieldOptions.title') }}</label>
                                <input 
                                  type="text" 
                                  v-model="selectedField.title" 
                                  class="form-control"
                                >
                              </div>
                      
                              <div class="form-group">
                                <div class="custom-control custom-switch">
                                  <input type="checkbox"
                                         class="custom-control-input"
                                         v-model="selectedField.required"
                                         :id="'field-required-toggle'"
                                  >
                                  <label class="custom-control-label" for="field-required-toggle">
                                    {{ $t('orgAdmin.form.details.fieldOptions.required') }}
                                  </label>
                                </div>
                              </div>
                      
                              <div class="form-group" v-if="selectedField.type === 'radio' || selectedField.type === 'checkbox'">
                                <label class="control-label d-flex justify-content-between align-items-center">
                                  {{ $t('orgAdmin.form.details.fieldOptions.choices') }}
                                  <button 
                                    @click="addOption" 
                                    class="btn btn-sm btn-outline-primary"
                                    type="button"
                                  >
                                    <i class="fa-solid fa-plus"></i>
                                  </button>
                                </label>
                                
                                <div class="options-container">
                                  <div v-for="(option, option_index) in selectedField.options" 
                                       :key="option_index" 
                                       class="option-item"
                                  >
                                    <input 
                                      type="text" 
                                      v-model="option.label" 
                                      class="form-control"
                                    >
                                    <button 
                                      @click="removeOption(option_index)" 
                                      class="btn btn-link btn-sm text-danger"
                                      :disabled="selectedField.options.length === 1"
                                      type="button"
                                    >
                                      <i class="fa-solid fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            
                            <div class="field-editor-empty" v-else>
                              <p class="text-muted text-center">
                                {{ $t('orgAdmin.form.details.selectField') }}
                              </p>
                            </div>
                          </b-card>
                        </div>
                      </div>
                    </div>

                    <!-- Reminder -->
                    <b-card class="mt-2" v-if="type !== 'create'">
                      <template #header>
                        <h4 class="mb-0">{{ $t('orgAdmin.form.details.reminder') }}</h4>
                      </template>
    
                      <validation-provider name="orgAdmin.form.attributes.sendReminder" v-slot="validationContext" vid="sendReminder">
                        <b-form-group>
                          <b-form-checkbox
                            :disabled="type === 'view'"
                            v-model="form.sendReminder"
                            :state="getValidationState(validationContext)"
                            switch
                          >
                            {{ $t('orgAdmin.form.attributes.sendReminder') }}
                          </b-form-checkbox>
                        </b-form-group>
                      </validation-provider>
    
                      <b-collapse v-model="form.sendReminder">
                        <validation-provider 
                          name="orgAdmin.form.attributes.reminderTitle" 
                          :rules="{ min: 3, max: 100, required: true }" 
                          v-slot="validationContext"
                        >
                          <b-form-group
                            :label="$t('orgAdmin.form.attributes.reminderTitle')"
                            label-cols-sm="2"
                          >
                            <b-form-input
                              :plaintext="type === 'view'"
                              v-model="form.reminderTitle"
                              :state="getValidationState(validationContext)"
                              :disabled="!form.sendReminder"
                            ></b-form-input>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
    
                        <validation-provider 
                          name="orgAdmin.form.attributes.reminderContent" 
                          :rules="{ max:500, required: true }" 
                          v-slot="validationContext"
                        >
                          <b-form-group
                            :label="$t('orgAdmin.form.attributes.reminderContent')"
                            label-cols-sm="2"
                          >
                            <b-form-textarea
                              :plaintext="type === 'view'"
                              v-model="form.reminderContent"
                              :state="getValidationState(validationContext)"
                              :disabled="!form.sendReminder"
                              rows="3"
                              max-rows="6"
                              class="overflow-auto"
                            ></b-form-textarea>
                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
    
                        <reminder-schedule
                          v-model="form.reminderTime"
                          :form="form"
                          :type="type"
                        ></reminder-schedule>
                      </b-collapse>
                    </b-card>    
                  </div>
                </div>

                <b-card class="mt-2" v-if="form.id">
                  <template #header>
                    <div class="d-flex justify-content-between align-items-center">
                      <h4 class="mb-0">{{ $t('orgAdmin.form.details.assignedTo') }}</h4>
                      <b-button 
                        v-if="!showAssign && type === 'view'" 
                        variant="primary" 
                        size="sm" 
                        @click="showAssign=true"
                      >
                        {{ $t('common.actions.assign') }}
                      </b-button>
                    </div>
                  </template>

                  <div class="form-row form-group">
                    <div v-for="(building, buildingId) in groupedLocations" :key="buildingId" class="mb-4">
                      <h2>{{ building.name }}</h2>
                      <div v-for="(map, mapId) in building.maps" :key="mapId" class="mb-3">
                        <h3 class="mb-2">{{ map.name }}:</h3>
                        <div class="d-flex flex-wrap gap-2">
                          <b-button 
                            v-for="location in map.locations" 
                            :key="location.id" 
                            variant="outline-secondary" 
                            size="sm" 
                            class="mr-2 mb-2"
                          >
                            {{ location.name }}
                            <i class="fa-solid fa-xmark ml-2" @click.stop="unassignForm(location.id)"></i>
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <b-collapse v-model="showAssign">
                    <form-resource-assign 
                      :resource-selected-callback="resourceSelectedCallback" 
                      :cancel-callback="cancelAssignCallback" 
                      :form="form" 
                    />
                  </b-collapse>
                </b-card>
              </b-form>
            </validation-observer>

            <div id="form" style="margin-top: 200px;"></div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Vue from "vue";
import * as commons from "@/commons";
import i18n from "@/i18n";
import FormResourceAssign from "@/components/views/feedback/FormResourceAssign";
import vuedraggable from 'vuedraggable';
import ReminderSchedule from "./ReminderSchedule.vue";

export default {
  components: {
    FormResourceAssign,
    ValidationProvider,
    ValidationObserver,
    vuedraggable,
    ReminderSchedule
  },
  data() {
    return {
      error: null,
      type: 'view',
      showAssign: false,
      form: {
        name: '',
        status: '',
        settings: null,
        fields: [],
        sendReminder: false,
        reminderTitle: '',
        reminderContent: '',
        reminderTime: ''
      },
      selectedField: null,
      selectedFieldIndex: null,
      activeTab: 0,
      drag: false,
      fieldTypes: [
        { 
          type: 'text', 
          label: this.$t('orgAdmin.form.details.field.text'),
          icon: 'fa-solid fa-font' 
        },
        { 
          type: 'textarea', 
          label: this.$t('orgAdmin.form.details.field.textArea'),
          icon: 'fa-solid fa-paragraph' 
        },
        { 
          type: 'radio', 
          label: this.$t('orgAdmin.form.details.field.radio'),
          icon: 'fa-solid fa-circle-dot' 
        },
        { 
          type: 'checkbox', 
          label: this.$t('orgAdmin.form.details.field.checkbox'),
          icon: 'fa-solid fa-square-check' 
        }
      ],
      highlightedFields: []
    }
  },
  created() {
    console.log('formDetails $route.name: ' + this.$route.name)
    this.type = this.$route.name == 'formNew' ? 'create' : 'view'
    if(this.type === 'view') {
      this.$store.dispatch('feedback/fetchFormDetails', this.$route.params.id)
    } else {
      this.$store.commit('feedback/SAVE_FORM_DETAILS_ENTITY', null)
      this.reset()
    }
  },
  computed: {
    ...mapState({
      formObj: state => state.feedback.formDetails.entity,
      currentOrganisation: state => state.feedback.formDetails.entity.organisation,
      currentLocations: state => state.feedback.formDetails.entity.locations
    }),
    organisation() {
      console.log('organisation() prefix ' + this.$route.params.prefix)
      return this.$route.params.prefix === 'admin' ? this.$store.state.organisation.organisationDetails.entity : this.$store.state.contextOrg
    },
    formStatus() {
      return [
        { id: "ACTIVE", text: i18n.t("orgAdmin.form.formStatus.active") },
        { id: "INACTIVE", text: i18n.t("orgAdmin.form.formStatus.inactive") }
      ]
    },
    groupedLocations() {
      let grouped = {};

      this.currentLocations.forEach(location => {
        const buildingId = location.map.building.id;
        const buildingName = location.map.building.name;
        const mapId = location.map.id;
        const mapName = location.map.name;

        if (!grouped[buildingId]) {
          grouped[buildingId] = {
            name: buildingName,
            maps: {}
          };
        }

        if (!grouped[buildingId].maps[mapId]) {
          grouped[buildingId].maps[mapId] = {
            name: mapName,
            locations: []
          };
        }

        grouped[buildingId].maps[mapId].locations.push(location);
      });

      return grouped;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "fields",
        disabled: this.type === 'view',
        ghostClass: "ghost",
        handle: ".handle"
      }
    },
    formFieldsSorted: {
      get() {
        return [...this.form.fields].sort((a, b) => (a.order - b.order));
      },
      set(value) {
        this.form.fields = value;
        // update order after setting new value
        this.updateFieldsOrder();
      }
    },
  },
  watch: {
    formObj: function(newVal, oldVal) {
      console.log('Prop form changed: ', newVal, ' | was: ', oldVal)
      this.reset()
    },
    'form.sendReminder': function(newVal, oldVal) {
      this.$refs.form.reset();
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    reset() {
      if(this.formObj){
        this.form.id = this.formObj.id
        this.form.name = this.formObj.name
        this.form.status = this.formObj.status
        this.form.settings = this.formObj.settings
        this.form.sendReminder = this.formObj.sendReminder
        this.form.reminderTitle = this.formObj.reminderTitle
        this.form.reminderContent = this.formObj.reminderContent
        this.form.reminderTime = this.formObj.reminderTime
        this.convertJsonSchemaToModel();
      } else {
        this.form.name = ''
        this.form.status = null
        this.form.settings = {}
        this.form.sendReminder = false
        this.form.reminderTitle = ''
        this.form.reminderContent = ''
        this.form.reminderTime = ''
      }
      if(this.$refs.form) {
        this.$refs.form.reset()
      }
    },
    back(){
      this.goBack()
    },
    cancel(){
      this.$refs.form.reset();
      this.reset();
      this.type='view'
    },
    editForm(){
      this.type='edit'
      this.convertJsonSchemaToModel();
    },
    addField(fieldType){
      let fieldOrder = this.form.fields.length;
      let fieldName = fieldType + '_' + fieldOrder
      if(fieldType === 'text') {
        this.form.fields.push({ type: fieldType, name: fieldName, label: 'Text', order: fieldOrder })
      } else if (fieldType === 'textarea') {
        this.form.fields.push({ type: fieldType, name: fieldName, label: 'Text area', order: fieldOrder })
      } else if(fieldType === 'radio') {
        let value = fieldName + '_opt_0'
        this.form.fields.push({ type: fieldType, name: fieldName, label: 'Radio', order: fieldOrder, options: [{ value: value, label:'' }] })
      } else if(fieldType === 'checkbox') {
        let value = fieldName + '_opt_0'
        this.form.fields.push({ type: fieldType, name: fieldName, label: 'Checkbox', order: fieldOrder, options: [{ value: value, label:'' }] })
      }
      this.selectedField = this.form.fields.at(-1)
      this.selectedFieldIndex = this.form.fields.length -1
      this.activeTab = 1
    },
    addFieldFromSchema(fieldType, fieldName, fieldTitle, fieldRequired){
      let newField;
      let fieldOrder = this.form.fields.length;
      if(fieldType === 'text' || fieldType === 'textarea') {
        newField = { type: fieldType, name: fieldName, title: fieldTitle, required: fieldRequired, order: fieldOrder }
      } else if(fieldType === 'radio') {
        newField = { type: fieldType, name: fieldName, title: fieldTitle, order: fieldOrder }
      } else if(fieldType === 'checkbox') {
        newField = { type: fieldType, name: fieldName, title: fieldTitle, order: fieldOrder }
      }
      return newField
    },
    editField(index){
      this.selectedField = this.form.fields[index]
      this.selectedFieldIndex = index
      this.activeTab = 1
    },
    removeField(index){
      this.form.fields.splice(index, 1)
      this.selectedField = null
    },
    moveUp(index){
      if(this.form.fields[index].order > 0){
        this.form.fields[index].order--
        this.form.fields[index - 1].order++
      }
    },
    moveDown(index){
      if(this.form.fields[index].order < this.form.fields.length) {
        this.form.fields[index].order++
        this.form.fields[index + 1].order--
      }
    },
    addOption(){
      const value = this.selectedField.name + '_opt_' + this.selectedField.options.length
      this.selectedField.options.push({ value: value, label:'' })
    },
    removeOption(index){
      this.selectedField.options.splice(index, 1)
    },
    resourceSelectedCallback(data){
      this.$store.dispatch('feedback/fetchFormDetails', this.$route.params.id)
      this.showAssign = false
    },
    cancelAssignCallback(){
      this.showAssign = false
    },
    unassignForm(locationId) {

      let self = this

      Vue.axios.post('/admin/feedback/forms/' + self.form.id + '/unassign', null, { params: {
            building_id: null,
            location_id: locationId
          }
        },
        {headers: {'Content-Type': 'application/json'}
        })
        .then(response => {
          self.$toastr.i(self.$i18n.t('orgAdmin.form.info.unassigned'))
          this.$store.dispatch('feedback/fetchFormDetails', this.$route.params.id)
        })
        .catch(error => {
          if (error.response.status === 403) {
            self.error = self.$i18n.t('orgAdmin.form.error.assignNotAuthorized')
          } else {
            commons.processRestError(error)
          }
        })
    },
    submit() {
      this.error = ""
      this.info = ""
      let self = this
      this.highlightedFieldIndexes = [];

      let config = this.convertModelToJsonSchema();
      //let org = (typeof this.building.organisation !== 'undefined') ? this.building.organisation : this.organisation;
      let org = this.organisation;

      const payload = {
        name: this.form.name,
        status: this.form.status,
        settings: config,
        sendReminder: this.form.sendReminder,
        reminderTitle: this.form.reminderTitle,
        reminderContent: this.form.reminderContent,
        reminderTime: this.form.reminderTime,
        organisation: org
      };

      const emptyTitles = this.form.fields.filter(f => !f.title || f.title.trim() === '');
      const invalidOptions = this.form.fields.filter(f =>
        (f.type === 'radio' || f.type === 'checkbox') &&
        f.options.some(o => !o.label || o.label.trim() === '')
      );
      
      if (emptyTitles.length > 0 && invalidOptions.length > 0) {
        this.error = this.$t('orgAdmin.form.error.emptyFieldTitle') + ' & ' + this.$t('orgAdmin.form.error.emptyOptionLabel');
      } else if (emptyTitles.length > 0) {
        this.error = this.$t('orgAdmin.form.error.emptyFieldTitle');
      } else if (invalidOptions.length > 0) {
        this.error = this.$t('orgAdmin.form.error.emptyOptionLabel');
      } else {
        this.error = '';
      }

      const emptyTitleIndexes = this.form.fields
        .map((f, idx) => (!f.title || f.title.trim() === '') ? idx : null)
        .filter(i => i !== null);

      const emptyOptionIndexes = this.form.fields
        .map((f, idx) =>
          (f.type === 'radio' || f.type === 'checkbox') &&
            f.options?.some(o => !o.label || o.label.trim() === '') ? idx : null
        )
        .filter(i => i !== null);

      this.highlightedFields = [...new Set([...emptyTitleIndexes, ...emptyOptionIndexes])];

      if (this.highlightedFields.length > 0) {
        return;
      }

      if(this.type === 'create') {
        Vue.axios.post('/admin/feedback/forms', JSON.stringify(payload),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {

          self.$toastr.i(i18n.t('orgAdmin.form.info.created'))
          self.$router.replace({
            name: 'formDetails',
            params: { id: response.data.id }
          })
          self.$store.dispatch('feedback/fetchFormDetails', this.$route.params.id)
          self.type = 'view'
          self.selectedFieldIndex = null
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      } else if(this.type === "edit") {
        Vue.axios.put('/admin/feedback/forms/' + this.form.id, JSON.stringify(payload),
            {
              headers: {'Content-Type': 'application/json'}
            }).then(response => {
          self.$toastr.i(i18n.t('orgAdmin.form.info.saved'))
          self.$store.dispatch('feedback/fetchFormDetails', this.$route.params.id)
          self.type = 'view'
          self.selectedFieldIndex = null
        }).catch(error => {
          if (error.response.status === 422) {
            self.$refs.form.setErrors(error.response.data)
          } else {
            commons.processRestError(error)
          }
        })
      }
    },
    deleteForm(){
      let self = this;
      this.confirmDialog({
        text: this.$t('orgAdmin.form.confirm.delete'),
        callbackYes: function(){
          Vue.axios.delete('/admin/feedback/forms/' + self.form.id)
              .then(response => {
                self.$toastr.i(i18n.t('orgAdmin.form.info.deleted'))
                self.goBack()
              })
              .catch(error => {
                commons.processRestError(error)
              });
        }
      })
    },
    convertModelToJsonSchema(){
      console.log("convertModelToJsonSchema")

      let config = {
        schema: {
          title: this.form.name,
          description: "",
          type: "object",
          properties: {}
        },
        options: {
          helper: '',
          fields: {}
        }
      }

      let i = 0;
      for (const field of this.formFieldsSorted) {
          let schemaType = null;
          if(field.type === 'text' || field.type === 'textarea') {
            schemaType = "string"
          } else if (field.type === 'radio') {
            schemaType = "radio"
          } else if ( field.type === 'checkbox') {
            schemaType = "checkbox"
          }
          config.schema.properties[field.name] = { type: schemaType, title: field.title, required: field.required }
          config.options.fields[field.name] = { type: field.type, order: i++ }

          if (field.options && field.options.length > 0) {
              config.schema.properties[field.name]["enum"] = field.options.map(option => option.value)
              config.options.fields[field.name]["optionLabels"] = field.options.map(option => option.label)
          }
      }

      return config;
    },
    convertJsonSchemaToModel(){
      console.log("convertJsonSchemaToModel")

      this.form.fields = []

      for (const [property, value] of Object.entries(this.form.settings.schema.properties)) {

        let fieldName = property
        let fieldType = value.type
        let fieldTitle = value.title
        let fieldRequired = value.required

        let fieldOptions = this.form.settings.options.fields[fieldName]
        if (fieldOptions) {
          fieldType = fieldOptions.type
        }

        let newField = this.addFieldFromSchema(fieldType, fieldName, fieldTitle, fieldRequired)

        if (value.enum) {
          newField.options = []

          for (let index = 0; index < value['enum'].length; ++index) {
            let value = this.form.settings.schema.properties[fieldName]['enum'][index]
            let label = this.form.settings.options.fields[fieldName].optionLabels[index]
            newField.options.push({ value: value, label: label })
          }
        }

        this.form.fields.push(newField)
      }
    },
    onDragEnd(evt) {
      this.drag = false;
      this.updateFieldsOrder();
    },
    updateFieldsOrder() {
      this.form.fields.forEach((field, index) => {
        field.order = index;
      });
    },
  }
}
</script>

<style scoped>
.field-wrapper {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-bottom: 12px;
  transition: all 0.3s ease;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}

.field-wrapper:hover {
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.field-wrapper.selected {
  border-color: #2196f3;
  background-color: #f3f9fe;
}

.field-header {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 6px 6px 0 0;
}

.field-title {
  flex-grow: 1;
  font-weight: 500;
  color: #2c3e50;
}

.field-editor {
  display: flex;
}

.field {
  width: 70%;
  float: left;
  align-items: stretch;
  padding: 16px;
}

.handle {
  cursor: move;
  color: #999;
  padding: 4px;
  margin-right: 8px;
  border-radius: 4px;
}

.handle:hover {
  background: #e9ecef;
  color: #666;
}

.field-actions {
  display: flex;
  gap: 12px;
}

.field-actions i {
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.field-actions i:hover {
  background: #e9ecef;
}

.form-check {
  margin-bottom: 8px;
}

.ghost {
  opacity: 0.5;
  background: #e3f2fd;
  border: 2px dashed #2196f3;
}

.field-types-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding: 8px;
}

.field-type-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.field-type-button:hover {
  background: #f8f9fa;
  border-color: #2196f3;
  box-shadow: 0 2px 4px rgba(33, 150, 243, 0.1);
}

.field-type-button i {
  font-size: 24px;
  margin-bottom: 8px;
  color: #2196f3;
}

.field-type-button span {
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}

.field-editor-panel {
  padding: 16px;
}

.field-editor-empty {
  padding: 32px 16px;
  text-align: center;
}

.options-container {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.option-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.option-item .btn-link {
  padding: 4px 8px;
  font-size: 16px;
}

.option-item .btn-link:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.custom-control.custom-switch {
  padding-left: 3.25rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #2196f3;
  background-color: #2196f3;
}

.highlight-invalid {
  border: 2px solid #dc3545 !important;
  background-color: #fff5f5;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
</style>