<template>
  <div class="building-table building-comparison">

    <div class="row mt-2 mb-4">
      <div class="input-group col-lg-6 align-content-center">
        <span class="input-group-prepend">
          <b-button class="btn btn-sm btn-primary" @click="clearFilter">
            {{ $t('common.actions.clear') }}
          </b-button>
        </span>
        <b-form-input type="text" :debounce="300" maxlength="50" :placeholder="$t('enterForSearch')" v-model="search"
          class="form-control form-control-sm" />
        <span class="input-group-append">
          <b-button class="btn btn-sm btn-primary" @click="fetchBuildingsList">
            <i class="fas fa-refresh mr-1"></i>
            {{ $t('common.actions.refresh') }}
          </b-button>
        </span>
      </div>
      <div class="col-lg-3 m-t-sm text-right">
        <row-count :page="currentPage" :size="itemsPerPage" :total="filteredBuildingCount" />
      </div>
      <div class="col-lg-3 text-right">
        <b-pagination size="sm" class="m-t-xs" align="right" v-model="currentPage" :total-rows="filteredBuildingCount"
          :per-page="itemsPerPage" @change="handlePageChange" />
      </div>
    </div>

    <!-- Table -->
    <b-table striped hover 
      :items="visibleBuildings" 
      :fields="fields" 
      :responsive="true"
      @head-clicked="onSort" 
      @row-clicked="openBuildingDetails">
      <template #cell(name)="data">
        {{ data.item.name }}
      </template>

      <template #cell(grossFloorArea)="data">
        {{ getFormattedValue( data.item.parameters.grossFloorArea, 'm²') }}
      </template>

      <template #cell(electricityTotal)="data">
        <template v-if="buildingUsage[data.item.id]">
          {{ getFormattedValue(buildingUsage[data.item.id].electricityTotal, 'kWh') }}
        </template>
        <template v-else>
          <i class="fas fa-spinner fa-spin"></i>
        </template>
      </template>

      <template #cell(electricityPerM2)="data">
        <template v-if="buildingUsage[data.item.id]">
          {{ getFormattedValue(getElectricityUsagePerSquareMeter(data.item.id), 'kWh/m²') }}
        </template>
        <template v-else>
          <i class="fas fa-spinner fa-spin"></i>
        </template>
      </template>

      <template #cell(electricityYoY)="data">
        <div style="text-align: center;">
          <template v-if="buildingUsage[data.item.id]">
            <span :class="getElectricityUsageTrend(data.item.id) >= 0 ? 'text-danger' : 'text-success'">
              {{ getFormattedValue(getElectricityUsageTrend(data.item.id), '%') }}
              <i v-if="getElectricityUsageTrend(data.item.id) > 0" :class="'fas fa-arrow-up'"></i>
              <i v-else-if="getElectricityUsageTrend(data.item.id) < 0" :class="'fas fa-arrow-down'"></i>
            </span>
          </template>
          <template v-else>
            <i class="fas fa-spinner fa-spin"></i>
          </template>
        </div>
      </template>

      <template #cell(waterTotal)="data">
        <template v-if="buildingUsage[data.item.id]">
          {{ getFormattedValue(buildingUsage[data.item.id].waterTotal, 'm³') }}
        </template>
        <template v-else>
          <i class="fas fa-spinner fa-spin"></i>
        </template>
      </template>

      <template #cell(waterPerM2)="data">
        <template v-if="buildingUsage[data.item.id]">
          {{ getFormattedValue(getWaterUsagePerSquareMeter(data.item.id), 'm³/m²') }}
        </template>
        <template v-else>
          <i class="fas fa-spinner fa-spin"></i>
        </template>
      </template>

      <template #cell(waterYoY)="data">
        <div style="text-align: center;">
          <template v-if="buildingUsage[data.item.id]">
            <span :class="getWaterUsageTrend(data.item.id) >= 0 ? 'text-danger' : 'text-success'">
              {{ getFormattedValue(getWaterUsageTrend(data.item.id), '%') }}
              <i v-if="getWaterUsageTrend(data.item.id) > 0" :class="'fas fa-arrow-up'"></i>
              <i v-else-if="getWaterUsageTrend(data.item.id) < 0" :class="'fas fa-arrow-down'"></i>
            </span>
          </template>
          <template v-else>
            <i class="fas fa-spinner fa-spin"></i>
          </template>
        </div>
      </template>

      <template #cell(heatingTotal)="data">
        <template v-if="buildingUsage[data.item.id]">
          {{ getFormattedValue(buildingUsage[data.item.id].heatingTotal, 'kWh') }}
        </template>
        <template v-else>
          <i class="fas fa-spinner fa-spin"></i>
        </template>
      </template>

      <template #cell(heatingPerM2)="data">
        <template v-if="buildingUsage[data.item.id]">
          {{ getFormattedValue(getHeatingUsagePerSquareMeter(data.item.id), 'kWh/m²') }}
        </template>
        <template v-else>
          <i class="fas fa-spinner fa-spin"></i>
        </template>
      </template>

      <template #cell(heatingYoY)="data">
        <div style="text-align: center;">
          <template v-if="buildingUsage[data.item.id]">
            <span :class="getHeatingUsageTrend(data.item.id) >= 0 ? 'text-danger' : 'text-success'">
              {{ getFormattedValue(getHeatingUsageTrend(data.item.id), '%') }}
              <i v-if="getHeatingUsageTrend(data.item.id) > 0" :class="'fas fa-arrow-up'"></i>
              <i v-else-if="getHeatingUsageTrend(data.item.id) < 0" :class="'fas fa-arrow-down'"></i>
            </span>
          </template>
          <template v-else>
            <i class="fas fa-spinner fa-spin"></i>
          </template>
        </div>
      </template>

      <template #cell(tail)="data">
        <div style="text-align: center;">
          <template v-if="buildingServerKpis[data.item.id]">
            <span :class="'tail-' + getFormattedTail(buildingServerKpis[data.item.id])" class="tail-bubble">{{ getFormattedTail(buildingServerKpis[data.item.id]) }}</span>
          </template>
          <template v-else>
            <i class="fas fa-spinner fa-spin"></i>
          </template>
        </div>
      </template>
    </b-table>

    <b-modal v-model="showBuildingDetailsModal" size="xl" :title="selectedBuilding?.name" hide-footer dialog-class="modal-90w">
      <building-dashboard
        v-if="selectedBuilding"
        :building="selectedBuilding"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import RowCount from "@/components/common/RowCount"
import BuildingDashboard from './BuildingDashboard.vue';
import _ from 'lodash'
import { numeric } from 'vuelidate/lib/validators';

export default {
  name: 'BuildingComparisonTable',

  components: {
    RowCount,
    BuildingDashboard
  },

  props: {
    comparisonMode: {
      type: String,
      required: true
    },
    selectedYears: {
      type: Array,
      required: true
    },
    selectedMonths: {
      type: Array,
      required: true
    },
    selectedMonthYear: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      search: '',
      sortBy: null,
      sortDesc: false,
      currentPage: 1,
      itemsPerPage: 20,
      internalSelectedYears: [...this.selectedYears],
      internalSelectedMonths: [...this.selectedMonths],
      internalMonthYear: this.selectedMonthYear,
      selectedBuilding: null,
      showBuildingDetailsModal: false,
      fields: [
        { key: 'name', label: this.$t('dashboard.table.headers.buildingName'), sortable: false },
        { key: 'grossFloorArea', label: this.$t('dashboard.table.headers.floorArea'), sortable: false },
        { key: 'electricityTotal', label: this.$t('dashboard.table.headers.electricity'), sortable: false },
        { key: 'electricityPerM2', label: this.$t('dashboard.table.headers.electricityPerM2'), sortable: false },
        { key: 'electricityYoY', label: this.$t('dashboard.table.headers.electricityTrend'), sortable: false },
        { key: 'waterPerM2', label: this.$t('dashboard.table.headers.waterPerM2'), sortable: false },
        { key: 'waterTotal', label: this.$t('dashboard.table.headers.water'), sortable: false },
        { key: 'waterYoY', label: this.$t('dashboard.table.headers.waterTrend'), sortable: false },
        { key: 'heatingTotal', label: this.$t('dashboard.table.headers.heating'), sortable: false },
        { key: 'heatingPerM2', label: this.$t('dashboard.table.headers.heatingPerM2'), sortable: false },
        { key: 'heatingYoY', label: this.$t('dashboard.table.headers.heatingTrend'), sortable: false },
        { key: 'tail', label: this.$t('dashboard.table.headers.tail'), sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters('dashboardTable', [
      'visibleBuildings',
      'filteredBuildingCount'
    ]),

    buildingUsage() {
      return this.visibleBuildings.reduce((acc, building) => {
        acc[building.id] = building.kpis || {};
        return acc;
      }, {});
    },

    buildingServerKpis() {
      return this.visibleBuildings.reduce((acc, building) => {
        acc[building.id] = building.serverKpis.filter(data => data.year === this.internalSelectedYears[0]) || {};
        acc[building.id] = acc[building.id][0]?.buildingTail.overall || "N/A";
        return acc;
      }, {});
    },

    loadingStates() {
      return this.visibleBuildings.reduce((acc, building) => {
        acc[building.id] = !this.buildingUsage[building.id];
        return acc;
      }, {});
    },

    availableYears() {
      const currentYear = new Date().getFullYear();
      return [
        currentYear,
        currentYear - 1,
        currentYear - 2
      ].map(year => ({
        value: year,
        text: year.toString()
      }));
    },

    monthOptions() {
      const monthNames = this.$t('dateRangePickerLabels.monthNames');
      return monthNames.map((name, index) => ({
        value: index,
        text: name
      }));
    },

    paginationInfo() {
      const from = (this.currentPage - 1) * this.itemsPerPage + 1;
      const to = Math.min(from + this.itemsPerPage - 1, this.filteredBuildingCount);
      return { from, to };
    }
  },

  watch: {
    comparisonMode: {
      immediate: false,
      handler(newMode) {
        if (newMode === 'monthByMonth') {
          this.$emit('set-comparison-mode', 'year');
          return;
        }
        this.updateDateRanges();
      }
    },

    selectedYears: {
      handler(newYears) {
        this.internalSelectedYears = [...newYears];
        this.updateDateRanges();
      },
      deep: true
    },

    selectedMonths: {
      handler(newMonths) {
        this.internalSelectedMonths = [...newMonths];
        this.updateDateRanges();
      },
      deep: true
    },

    selectedMonthYear(newYear) {
      this.internalMonthYear = newYear;
      this.updateDateRanges();
    }
  },

  created() {
    this.initializeComponent();
  },

  methods: {
    ...mapActions('dashboardTable', [
      'fetchBuildingsList',
      'loadVisibleBuildingsData',
      'setFilters',
      'initializeData',
      'setPagination'
    ]),

    onSort(field) {
      this.sort(field.key);
    },

    getFormattedValue(value, unit) {
      if (value === null || value === undefined) return '-';
      const number = parseFloat(value);
      if (isNaN(number)) return '-';
      return `${number.toFixed(2)} ${unit}`;
    },

    getFormattedTail(value){
      switch(parseInt(value)) {
        case 1:
          return "I"
        case 2:
          return "II"
        case 3:
          return "III"
        case 4:
          return "IV"
        default:
          return "-"
      } 
    },

    setMode(mode) {
      if (mode !== 'monthByMonth') {
        this.$emit('set-comparison-mode', mode);
      }
    },

    handleYearChange() {
      this.$emit('update:selectedYears', this.internalSelectedYears);
    },

    handleMonthChange() {
      this.$emit('update:selectedMonths', this.internalSelectedMonths);
    },

    handleMonthYearChange() {
      this.$emit('update:selectedMonthYear', this.internalMonthYear);
    },

    updateDateRanges() {

      if (this.comparisonMode === 'monthByMonth') {
        return;
      }
      
      const dateRange = this.comparisonMode === 'month'
        ? {
          currentPeriod: {
            from: new Date(this.internalMonthYear, this.internalSelectedMonths[0], 1),
            to: new Date(this.internalMonthYear, this.internalSelectedMonths[0] + 1, 0, 23, 59, 59)
          },
          previousPeriod: {
            from: new Date(this.internalMonthYear, this.internalSelectedMonths[1], 1),
            to: new Date(this.internalMonthYear, this.internalSelectedMonths[1] + 1, 0, 23, 59, 59)
          }
        }
        : {
          currentPeriod: {
            from: new Date(this.internalSelectedYears[0], 0, 1),
            to: new Date(this.internalSelectedYears[0], 11, 31, 23, 59, 59)
          },
          previousPeriod: {
            from: new Date(this.internalSelectedYears[1], 0, 1),
            to: new Date(this.internalSelectedYears[1], 11, 31, 23, 59, 59)
          }
        };

      this.setFilters({ dateRange });
      this.loadVisibleBuildingsData();
    },

    async initializeComponent() {
      await this.initializeData();
      this.updateDateRanges();
    },

    handlePageChange(page) {
      this.setPagination({ currentPage: page });
      this.loadVisibleBuildingsData();
    },

    getUsageForBuildingPerSquareMeter(buildingId, type) {
      const building = this.visibleBuildings.find(b => b.id === buildingId);

      if (!building || !building.kpis) return null;

      if (building.parameters.grossFloorArea === null) return 'missing area';

      switch (type) {
        case 'ELECTRICITY':
          return building.kpis.electricityPerM2;
        case 'WATER':
          return building.kpis.waterPerM2;
        case 'DISTRICT_HEATING':
          return building.kpis.heatingPerM2;
        default:
          return null;
      }
    },

    getUsageTrendForBuilding(buildingId, type) {
      const building = this.visibleBuildings.find(b => b.id === buildingId);
      if (!building || !building.kpis) return null;

      switch (type) {
        case 'ELECTRICITY':
          return building.kpis.electricityYoY;
        case 'WATER':
          return building.kpis.waterYoY;
        case 'DISTRICT_HEATING':
          return building.kpis.heatingYoY;
        default:
          return null;
      }
    },

    clearFilter() {
    },

    getElectricityUsagePerSquareMeter(buildingId) {
      return this.getUsageForBuildingPerSquareMeter(buildingId, 'ELECTRICITY');
    },

    getWaterUsagePerSquareMeter(buildingId) {
      return this.getUsageForBuildingPerSquareMeter(buildingId, 'WATER');
    },

    getHeatingUsagePerSquareMeter(buildingId) {
      return this.getUsageForBuildingPerSquareMeter(buildingId, 'DISTRICT_HEATING');
    },

    getElectricityUsageTrend(buildingId) {
      return this.getUsageTrendForBuilding(buildingId, 'ELECTRICITY');
    },

    getWaterUsageTrend(buildingId) {
      return this.getUsageTrendForBuilding(buildingId, 'WATER');
    },

    getHeatingUsageTrend(buildingId) {
      return this.getUsageTrendForBuilding(buildingId, 'DISTRICT_HEATING');
    },

    openBuildingDetails(building) {
      if (building && building.id) {
        this.selectedBuilding = building;
        this.showBuildingDetailsModal = true;
      }
    },

  }
};
</script>

<style scoped>
.building-table {
  margin-top: 20px;
}

.building-table table {
  width: 100%;
  border-collapse: collapse;
}

.ibox-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-group {
  display: flex;
  align-items: center;
}

::v-deep .modal-90w {
  max-width: 90vw !important;
  width: 90vw !important;
  margin: 1.75rem auto !important;
}

.tail-bubble{
  display: inline-block;
  border-radius: 50%;
  color: black;
  font-weight: 400;
  width: 1.5rem;
  height: 1.5rem;
  padding: 2px;
  background-color: #E2E0E0;
}

.tail-I {
  background-color: #4a7b57;
}

.tail-II {
  background-color: #82a03a;
}

.tail-III {
  background-color: #bac41e;
}

.tail-IV {
  background-color: #f2e901;
}

</style>