<template>
  <div>
    <div class="form-group row m-b-sm" v-if="userResourceRoles.length != 0">
      <div class="col-lg-6">
        <div class="input-group">
          <span class="input-group-prepend">
            <b-button class="btn btn-sm btn-primary" :disabled="table.filter == null || table.filter.length == 0"
              @click="clearFilter"> {{
                $t('common.actions.clear')
              }}</b-button>
          </span>
          <b-form-input type="text" v-model="table.filter" debounce="300" max="50" :placeholder="$t('enterForSearch')"
            class="form-control form-control-sm" />
        </div>
      </div>
      <div class="col-lg-2 m-t-sm text-right">
        <row-count :size="table.size" :page="table.page" :total="groupedUserRoles.length"></row-count>
      </div>
      <div class="col-lg-4 text-right" v-if="groupedUserRoles.length && groupedUserRoles.length > table.size">
        <b-pagination v-if="groupedUserRoles.length" size="sm" class="m-t-xs" align="right"
          :total-rows="groupedUserRoles.length" v-model="table.page" :per-page="table.size"></b-pagination>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <b-table responsive hover striped v-if="groupedUserRoles.length != 0" class="text-nowrap" 
          :filter="table.filter"
          :fields="table.fields" :current-page="table.page" :per-page="table.size" :items="groupedUserRoles"
          :sort-by.sync="table.sortBy" :sort-desc.sync="table.sortDesc">
          <template #cell(userUsername)="data">
            <b-link :to="{ path: routePrefix + '/users/' + data.item.userId }">{{ data.value }}</b-link>
          </template>
          <template #cell(userStatus)="data">
            <span class="label" :class="[data.item.status == 'BLOCKED' ? 'label-danger' : 'label-primary']">{{
              $i18n.t('enums.UserStatus.' + data.value)}}</span>
          </template>
          <template #cell(resourceName)="data">
            <!-- Show single location directly -->
            <div v-if="data.item.locations.length === 1">
              {{ data.item.locations[0] }}
            </div>

            <!-- Show count for multiple locations with popover -->
            <div v-else class="d-flex align-items-center">
              <span class="location-count cursor-pointer" :id="`location-badge-${data.item.userId}-${data.item.role}`">
                {{ data.item.locations.length }} {{ isBuildingRole(data.item.role) ? $t('orgAdmin.userResource.buildings') : $t('orgAdmin.userResource.locations') }}
              </span>
              <b-popover :target="`location-badge-${data.item.userId}-${data.item.role}`" triggers="hover"
                placement="top" custom-class="locations-popover">
                <template #title>
                  <div class="d-flex align-items-center">
                    <i class="fa fa-map-marker mr-1"></i>
                    {{ isBuildingRole(data.item.role) ? $t('orgAdmin.userResource.buildings_title') : $t('orgAdmin.userResource.locations_title') }} {{ $t('orgAdmin.userResource.with_same_role') }}
                  </div>
                </template>
                <div class="location-list">
                  <div v-for="(location, index) in data.item.locations" :key="index" class="location-item">
                    {{ location }}
                  </div>
                </div>
              </b-popover>
            </div>
          </template>
          <template #cell()="data">{{ data.value }}</template>
        </b-table>
        <span v-else class="alert alert-info d-inline-block">{{ $t('common.noData') }}</span>

        <div class="row" v-if="canAdd">
          <div class="col-lg-12 buttons">
            <router-link :to="{ path: routePrefix + '/users/new/' }">
              <button class="btn btn-sm btn-primary pull-left mr-1">
                <i class="fa fa-plus"></i>&nbsp;{{ $t('orgAdmin.user.actions.new') }}
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n'
import { mapState } from 'vuex'
import RowCount from '@/components/common/RowCount.vue';

export default {
  components: {
    RowCount
  },
  props: {
    routePrefix: {
      default: ''
    },
    canAdd: {
      type: Boolean
    }
  },
  data() {
    return {
      error: null,
      info: null,
      table: {
        filter: '',
        sortBy: 'name',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          { 'value': 5 },
          { 'value': 25 },
          { 'value': 50 },
          { 'value': 100 }
        ],
        fields: [
          {
            key: 'userUsername',
            label: this.$i18n.t('orgAdmin.userResource.attributes.username'),
            sortable: true,
          },
          {
            key: 'userName',
            label: this.$i18n.t('orgAdmin.userResource.attributes.name'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return item.userFirstName + ' ' + item.userLastName
            }
          },
          {
            key: 'userStatus',
            label: this.$i18n.t('orgAdmin.userResource.attributes.status'),
            sortable: true,
            class: ["text-center"]
          },
          {
            key: 'role',
            label: this.$i18n.t('orgAdmin.userResource.attributes.role'),
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true,
            formatter: (value, key, item) => {
              return i18n.t('enums.UserRole.' + item.role)
            }
          },
          {
            key: 'resourceName',
            label: this.$i18n.t('orgAdmin.userResource.attributes.resourceName'),
            sortable: true,
          }
        ]
      }
    }
  },
  methods: {
    isBuildingRole(role) {
      return role === 'BUILDING_MANAGER';
    },
    clearFilter() {
      this.table.filter = null
    }
  },
  computed: {
    ...mapState({
      userResourceRoles: state => state.userManagement.userResourceRoles
    }),
    groupedUserRoles() {
      const grouped = {};

      this.userResourceRoles.forEach(item => {
        const key = `${item.userId}_${item.role}`;

        if (!grouped[key]) {
          grouped[key] = {
            ...item,
            locations: [item.resourceName]
          };
        } else {
          grouped[key].locations.push(item.resourceName);
        }
      });

      return Object.values(grouped);
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.location-count {
  color: #3490dc;
  text-decoration: underline dotted;
}

.location-count:hover {
  color: #1c6ab7;
}

.locations-popover {
  min-width: 300px;
  max-width: 400px;
}

.location-list {
  max-height: 200px;
  overflow-y: auto;
}

.location-item {
  padding: 4px 0;
  border-bottom: 1px solid #f0f0f0;
}

.location-item:last-child {
  border-bottom: none;
}
</style>