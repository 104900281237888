<template>

  <div>
    <div v-if="show">
      <div class="form-group row m-b-sm">
        <div class="col-lg-5 offset-lg-3 m-t-sm text-right">
          <row-count :size="table.size" :page="table.page" :total="table.total"></row-count>
        </div>

        <div class="col-lg-4 text-right">
          <b-pagination size="sm" class="m-t-xs" align="right"
                        :total-rows="table.total"
                        v-model="table.page"
                        :per-page="table.size"></b-pagination>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div v-for="(response, index) in formWordcloud" :key="index">
            <highcharts v-if="response" :options="response.wordcloud" :ref="response.title"></highcharts>
            <div v-else>{{ $t('feedback.feedbackView.noResponses') }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <b-table responsive hover striped
                   class="text-nowrap"
                   :filter="table.filter"
                   :fields="table.fields"
                   :current-page="table.page"
                   :per-page="table.size"
                   :items="fetchData"
                   :sort-by.sync="table.sortBy"
                   :sort-desc.sync="table.sortDesc"
                   ref="table">
            <template #cell(username)="data">
              {{ data.value ? data.value : "Anonymous" }}
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <div v-else>{{ $t('feedback.feedbackView.noResponses') }}</div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import RowCount from '@/components/common/RowCount';
import Vue from "vue";
import moment from 'moment'
import * as commons from "@/commons";
import keywordExtractor from 'keyword-extractor';

export default {
  components: {
   RowCount
  },
  props: {
    field: {
      type: Object
    }
  },
  data() {
    return {
      error: null,
      info: null,
      show: true,
      table: {
        filter: '',
        sortBy: 'createdAt',
        sortDesc: false,
        page: 1,
        size: 10,
        total: 0,
        paginationOptions: [
          {'value': 5},
          {'value': 25},
          {'value': 50},
          {'value': 100}
        ],
        fields: [
          {
            key: 'id',
            label: this.$i18n.t('feedback.feedbackView.responses.attributes.id'),
            sortable: false,
          },
          {
            key: 'date',
            label: this.$i18n.t('feedback.feedbackView.responses.attributes.date'),
            sortable: false,
            sortByFormatted: true,
            filterByFormatted: true,
          },
          {
            key: 'username',
            label: this.$i18n.t('feedback.feedbackView.responses.attributes.username'),
            sortable: false,
            sortByFormatted: true,
            filterByFormatted: true,
          },
          {
            key: 'response',
            label: this.$i18n.t('feedback.feedbackView.responses.attributes.response'),
            sortable: false,
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.userInfo,
      contextOrg: state => state.contextOrg,
      selectedForm: state => state.feedback.selectedForm,
      selectedLocation: state => state.feedback.selectedLocation,
      feedbackConfig: state => state.feedback.feedbackConfig,
      wordcloudData: state => state.feedback.wordcloudData
    }),
    formWordcloud() {
      let charts = []
      let responses = this.wordcloudData.wordcloudData ? this.wordcloudData.wordcloudData : []

      if (responses.length === 0) {
        return charts
      }
      for (const response of responses) {
        let formFields = convertJsonSchemaToModel(this.selectedForm)

        let currentField = null
        for (const field of formFields) {
          if (field.name === response.name) {
            currentField = field
          }
        }

        if (currentField !== null && this.field.name === currentField.name) {
          let string = response.responses
            .map(item => {
              return item.response;
            })
            .join(" ");

          let extraction_result =
            keywordExtractor.extract(string, {
              language: "english", //TO-DO: take the language from somewhere, user settings or building location or add language defition to form???
              remove_digits: true,
              return_changed_case: true,
              remove_duplicates: false

            });

          let chartDef = JSON.parse(JSON.stringify(chartWordcloudOptionsBase));
          let wordCounts = {};

          for (let word of extraction_result) {
            wordCounts[word] = (wordCounts[word] || 0) + 1;
          }

          let data = Object.keys(wordCounts).map(word => ({ name: word, weight: wordCounts[word] }));

          chartDef.series.push({ data });
          charts.push({ fieldName: response.name, title: currentField.title, wordcloud: chartDef })
        }
      }

      return charts;
    }
  },
  methods: {
    clearFilter() {
      this.table.filter = null
    },
    fetchData(pageParams){
      const pageable = {
        page: this.table.page - 1,
        size: this.table.size,
        sort: this.table.sortBy ? this.table.sortBy.concat(",").concat(this.table.sortDesc ? "DESC" : "ASC") : ""
      }

      let promise = Vue.axios.get('/admin/feedback/forms/responses-texts', {
        params: {
          form_id: this.selectedForm.id,
          location_id: this.selectedLocation.id,
          field_name: this.field.name,
          dateTimeFrom: moment(this.feedbackConfig.dateRange.startDate).toISOString(),
          dateTimeTo: moment(this.feedbackConfig.dateRange.endDate).toISOString(),
          ...pageable
        }
      })

      return promise.then(response => {
        this.table.page = response.data.pageable.pageNumber + 1
        this.table.total = response.data.totalElements
        if(this.table.total === 0) {
          this.show = false;
        }
        return(response.data.content)
      }).catch(error => {
        commons.processRestError(error)
        return [];
      })
    }
  },
  watch: {
    contextOrg: function(newVal, oldVal) {
      if(this.$refs.table) this.$refs.table.refresh()
    },
    selectedForm: function(newVal, oldVal) {
      if(this.$refs.table) this.$refs.table.refresh()
    },
    selectedLocation: function(newVal, oldVal) {
      if(this.$refs.table) this.$refs.table.refresh()
    },
    feedbackConfig: function(newVal, oldVal) {
      if(this.$refs.table) this.$refs.table.refresh()
    },
  }
}

function convertJsonSchemaToModel(form) {

  let fields = []
  if(form === null) {
    return fields
  }
  for (const [property, value] of Object.entries(form.settings.schema.properties)) {
    let fieldName = property
    let fieldType = value.type
    let fieldTitle = value.title
    let fieldRequired = value.required

    let fieldOptions = form.settings.options.fields[fieldName]
    if (fieldOptions) {
      fieldType = fieldOptions.type
    }

    let newField = addFieldFromSchema(fields.length, fieldType, fieldName, fieldTitle, fieldRequired)

    if (value.enum) {
      newField.options = []

      for (let index = 0; index < value['enum'].length; ++index) {
        let value = form.settings.schema.properties[fieldName]['enum'][index]
        let label = form.settings.options.fields[fieldName].optionLabels[index]
        newField.options.push({value: value, label: label})
      }
    }

    fields.push(newField)
  }
  return fields;
}

function addFieldFromSchema(fieldOrder, fieldType, fieldName, fieldTitle, fieldRequired){
  let newField;
  if(fieldType == 'text' || fieldType == 'textarea') {
    newField = { type: fieldType, name: fieldName, title: fieldTitle, required: fieldRequired, order: fieldOrder }
  } else if(fieldType == 'radio') {
    newField = { type: fieldType, name: fieldName, title: fieldTitle, order: fieldOrder }
  } else if(fieldType == 'checkbox') {
    newField = { type: fieldType, name: fieldName, title: fieldTitle, order: fieldOrder }
  }
  return newField
}

let chartWordcloudOptionsBase = {
  exporting: {
    buttons: {
      contextButton: {
        verticalAlign: 'bottom',
        y: 0
      }
    }
  },
  credits: false,
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'wordcloud',
  },
  title: {
    text: '',
    align: 'left'
  },
  series: [],
  colors: ['#F90093', '#025286', '#61AEB7', '#2C6E49', '#FFE347', '#FFC000', '#E94F37', '#A31621', '#C3979F', '#36151E']
}
</script>
